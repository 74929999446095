import {
  AdvancedAlphanumericBarcodeUseState,
  DoctorBarcodeMode,
  FormType,
  OrderExportTrigger,
  OrderRuleActionType,
  OrderRuleConditionType,
  ShortCodeMode,
  SplitMode,
} from 'interfaces/api';

export default {

  nav: {
    exportSettings: 'Export settings',
    mainMaterials: 'Main materials',
    data: 'Common Data',
  },
  rules: {
    header: 'Workflow Manager',
    name: 'Rule name',
    group: 'Group',
    description: 'Description',
    // eslint-disable-next-line id-denylist
    undefined: 'undefined',
    showAutomated: 'Show auto generated',
    conditions: {
      label: 'Conditions',
      add: 'Add condition',
      notOperator: 'Not operator',
      type: 'Condition type',
      is: 'is',
      isNot: 'is not',
      notSelected: 'Condition type not selected',
      types: {
        // [OrderRuleConditionType.ConditionSet]: 'Condition set',
        [OrderRuleConditionType.CostUnit]: 'Cost unit',
        [OrderRuleConditionType.Doctors]: 'Doctor',
        [OrderRuleConditionType.DoctorGroups]: 'Doctor Group',
        [OrderRuleConditionType.Gender]: 'Gender',
        [OrderRuleConditionType.Feature]: 'Feature',
        [OrderRuleConditionType.Form]: 'Form',
        [OrderRuleConditionType.FormType]: 'Form Type',
        [OrderRuleConditionType.Localization]: 'Localization',
        [OrderRuleConditionType.Product]: 'Product',
        [OrderRuleConditionType.Requirement]: 'Requirement',
        [OrderRuleConditionType.Status]: 'Status',
        [OrderRuleConditionType.UserType]: 'User Type',
      },
    },
    actions: {
      label: 'Actions',
      add: 'Add action',
      type: 'Action type',
      notSelected: 'Action type not selected',
      types: {
        [OrderRuleActionType.ShowText]: 'Show text',
        [OrderRuleActionType.ShowHint]: 'Show hint',
        [OrderRuleActionType.AddRequirement]: 'Add requirement',
        [OrderRuleActionType.RemoveRequirement]: 'Remove requirement',
        [OrderRuleActionType.MandatoryField]: 'Mandatory field',
        [OrderRuleActionType.StatusPrevention]: 'Prevent status',
        [OrderRuleActionType.Anamnesis]: 'Anamnesis',
        [OrderRuleActionType.FreetextMandatory]: 'Freetext mandatory',
        [OrderRuleActionType.DiagnosisMandatory]: 'Diagnosis mandatory',
        [OrderRuleActionType.IncompatibleRequirement]: 'Incompatible requirements',
        [OrderRuleActionType.WorkstationMandatory]: 'Workstation mandatory',
        [OrderRuleActionType.DialysisMandatory]: 'Dialysis mandatory',
        [OrderRuleActionType.MaxRequirements]: 'Max Anforderungen',
      },
    },
    created_at: 'Created at',
    controls: {
      add: 'Add Rule',
      delete: {
        label: 'Delete rule',
        notifications: {
          confirm: 'Confirm delete',
          success: 'Deleted rule successfully',
        },
      },
    },
    preferences: {
      orderAutoRequirementHintRule: {
        label: 'Auto generate requirement hint rules',
        description: 'Include hints from ll_hinweis_zu_anf in modal after order execution',
      },
    },
  },
  export: {
    header: 'Export Settings',
    save: 'Save settings',
    fieldId: 'LDT Field ID',
    preAppendValue: 'Pre Append Value',
    notifications: {
      success: 'Saved settings successfully',
      error: 'An error occurred',
    },
    shortCodes: {
      [ShortCodeMode.ShortName]: 'Doctor short name',
      [ShortCodeMode.InternalCode]: 'Short code internal',
      [ShortCodeMode.ExternalCode]: 'Short code external',
    },
    exportTriggers: {
      [OrderExportTrigger.WaitForLaboratory]: 'Wait for lab',
      [OrderExportTrigger.TimeInMinutes]: 'Time in Minutes',
    },
    general: {
      cardTitle: 'General export settings',
      exportPath: 'Export path',
      requestPresentationLdtExport: 'Request presentation in export',
      requestPresentationOaAnswer: 'Request presentation in OAAnswer',
      ldtExportTrigger: 'Trigger for export',
      ldtExportDelayMin: 'Export delay in minutes',
    },
    ldt: {
      title: 'LDT Settings',
      furtherFields: 'Additional LDT export fields',
      patientPhone: 'Patient Phone',
      patientEmail: 'Patient E-Mail',
      crownRumpLength: 'Crown-rump length',
      nuchalTranslucency: 'Nuchal translucency',
      cycleLength: 'Cycle length',
      cycleDay: 'Cycle day',
      anamnesticInfo: 'Anamnestic information',
      bmi: 'BMI calculation export',
    },
    freeText: {
      title: 'Free text definition',
      defaultRequest: 'Default Request',
    },
    optionalFields: {
      title: 'Export optional LDT fields',
      add: 'Add field',
      export: 'export',
    },
    bak: {
      title: 'Microbiology',
      fields: 'Microbiology Fields',
      leftRight: 'Left right',
      operative: 'Operative',
      examination: 'Examination',
      examinationId: 'Examination ID',
      examinationText: 'Examination comment',
      material: 'Material',
      materialId: 'Material ID',
      materialText: 'Material comment',
      origin: 'Origin of material',
      originText: 'Origin comment',
      originSite: 'Site of sampling',
      notes: 'Notes',
    },
    veterinary: {
      title: 'Veterinary',
      fields: 'Veterinary fields',
      animalName: 'Animal name',
      animalBirthdate: 'Animal birthday',
      animalSpecies: 'Animal species',
      animalOwnerName: 'Owner name',
      animalOwnerInfo: 'Owner additional info',
      animalOwnerStreet: 'Owner street',
      animalOwnerHousenr: 'Owner house number',
      animalOwnerZip: 'Owner zip code',
      animalOwnerCity: 'Owner city',
      animalSize: 'Animal size',
      animalWeight: 'Animal weight',
      animalRace: 'Animal race',
      animalSpeciesOther: 'Other animal species',
      animalChipnr: 'Animal chip number',
      animalGender: 'Animal gender',
    },
    austrian: {
      tabTitle: 'Austria',
      fields: 'Austrian fields',
      title: 'Title',
      firstName: 'First name',
      lastName: 'Last name',
      birthday: 'Birthday',
      insuranceNumber: 'Insurance Number',
      country: 'Country',
      zip: 'Zip code',
      city: 'City',
      street: 'Street',
      houseNumber: 'House number',
      addressAdditional: 'Address additional info',
      employer: 'Employer',
      employerPlace: 'Place of employer',
      chargeType: 'Charge type',
    },
    chBags: {
      tabTitle: 'Insurance codes',
      formTitle: 'Insurance name',
      code: 'Code',
    },
  },
  general: {
    realCancellation: 'Order cancellation',
    realCancellationDescription: 'Time span within which an order can be set to the cancelled status.',
    realCancellationTimeRange: 'Order Cancellation time range',
    reportsTimeRange: 'Reports time range',
    autocompleteEntries: 'Autocomplete Entries',
    alwaysShowBlocks: {
      label: 'Always show requirement blocks',
      description: 'If disabled, blocks will only be shown if the count of all requirements on screen exceed 100.',
    },
    blockSize: 'Wizard requirements block size',
    differentBlockSizeOnHome: 'Different requirements block size on home',
    blockSizeOnHome: 'Requirements block size on home',
    enableCreateEditProfilesText: 'Profiles can be saved and edited in the order wizard',
    dynamicProbeTypeStaticReprint: {
      label: 'Dynamic probe type static reprint',
      description: 'If enabled, the expended dynamic probe types will be reprinted without counting up',
    },
    profilesSetDiagnosisText: 'Set diagnosis in profiles',
    profilesSetFreeText: 'Set freetext in profiles',
    profilesDefaultToAllOthers: {
      label: 'Apply profiles for all other cost bearers',
      description: 'In the quick selection, the option “Apply profiles for all other cost units” should be set to Yes by default.',
    },
    showPrePrint: 'Show Pre-print button',
    prePrintIsC2A: 'Pre-print button as c2a',
    showRoomNumber: 'Show room number',
    showRoomNumberDescription: 'Show the room number next to the doctors name in the order list',
    hideDiagnosisFields: 'Do not display diagnostic fields',
    hideDiagnosisFieldsDescription: 'Do not display diagnostic information and the diagnosis inputs controls on the user interface',
    sampleDateTimeButton: {
      label: 'Sample date',
      description: 'Set sample date manually when executing',
    },
    sampleInLaboratoryButton: {
      label: 'Sample in laboratory',
      description: 'Execute button is split up into execute and take sample in laboratory',
    },
    sampleInLaboratoryButtonFirst: 'Show sample in laboratory button first',
    hideAdditionalInfo: {
      label: 'Hide additional infos in wizard',
      description: 'Hides patient additional infos and order reason',
    },
    patientHideFindingsMedications: {
      label: 'Hide Findings and Medications',
      description: 'Hide Findings and Medications in Burger Menu',
    },
    patientTopBarFindingsMedications: {
      label: 'Show Findings and Medications in Top Bar',
      description: '',
    },
    bakModalBlockButtons: {
      label: 'BAK Block Buttons',
      description: '',
    },
    disableHelgaImage: {
      label: 'Hide Helga images in modal views',
      description: '',
    },
    fillSampleDateFromScheduleDate: {
      label: 'Fill Sample Date From Schedule Date',
      description: '',
    },
    preferScheduleDateMode: {
      label: 'Prefer schedule date mode',
      description: 'Schedule date is mandatory, save and execute are swapped depending on schedule date.',
    },
    newOrderShowSelectPatient: {
      label: 'New order patient select modal initially opened',
      description: '',
    },
    openDuplicatePatientOrdersModal: {
      label: 'Open duplicate patient orders modal automatically if found',
      description: '',
      status: {
        saved: 'Saved orders',
        prePrinted: 'Preprinted orders',
        amendment: 'Amendment orders',
        executed: 'Executed orders',
      },
    },
    subRequirementsInBasket: {
      label: 'Disable requirement if already exists as sub requirement or analysis',
      description: '',
    },
    searchDirectInput: {
      label: 'Direct Input in wizard search control',
      description: '',
    },
    showCostUnitSelect: {
      label: 'Show cost unit select',
      description: 'Shows a modal with cost unit select after a patient without cost unit is selected',
    },
    showWorkstationSelect: {
      label: 'Show workstation select',
      description: 'When executing the order without a workstation, a modal for workstation selection opens',
    },
    showOfflineWorkstationSelect: {
      label: 'Show offline workstation select',
      description: 'When executing the order with a offline workstation, a modal for workstation selection opens',
    },
    hideOfflineWorkstations: {
      label: 'Hide offline workstations',
      description: '',
    },
    alwaysShowWorkstationSelect: {
      label: 'Always show workstation select',
      description: 'When executing the order the modal for workstation selection always opens',
    },
    rememberLastWorkstation: {
      label: 'Remember workstation for user',
      description: 'Selected workstation will be remembered and set by default for the logged in user',
    },
    rememberLastDeviceWorkstation: {
      label: 'Remember workstation on device',
      description: 'Selected workstation will be remembered and set by default for the device',
    },
    hideInsuranceInInvoiceTo: {
      label: 'Hide insurance in invoice to select',
      description: '',
    },
    defaultTreatmentReason: {
      label: 'Default treatment reason',
      description: '',
    },
    defaultReportCopySetting: {
      label: 'Default setting for the report copy of the order',
      description: '',
    },
    popoverInvoiceTo: {
      label: 'Show invoice to select in popover',
      description: '',
    },
    separateInvoiceTo: {
      label: 'Separate invoice to costs',
      description: '',
    },
    popoverHideAnalyses: {
      label: 'Hide analyses info in popover',
      description: '',
    },
    printSettings: 'Print Settings',
    poolSettings: {
      title: 'Pool Settings',
      maxPoolSize: 'Maximum Pool Size ("Mega Pool")',
      showBeforeAfterDialysis: 'Show before or after dialysis in the wizard',
      beforeAfterDialysisMustBeSpecified: 'Before or after dialysis must be specified',
    },
    showExttnr: {
      label: 'Show Exttnr',
      description: 'Shows the exttnr in the patient meta fields in wizard',
    },
    showProfileAlreadyInBasketWarning: {
      label: 'Show profile requirements already in basket warning',
      description: '',
    },
    showExttnrLastUnderline: {
      label: 'Show Exttnr after last Underline',
      description: 'Special form of exttnr to split for underline and only show last part. e.g.: 1234_5678_9090 becomes 9090',
    },
    pregnancySwitch: {
      label: 'Pregnancy Switch',
      description: 'Show pregnancy switch in burger menu',
    },
    infectiousSwitch: {
      label: 'Infectious Switch',
      description: 'Show infectious switch in burger menu',
    },
    checkupSwitch: {
      label: 'Checkup Switch',
      description: 'Show checkup switch in burger menu',
    },
    urgentSwitch: {
      label: 'Urgent Switch',
      description: 'Show urgent switch in burger menu',
    },
    emergencySwitch: {
      label: 'Emergency Switch',
      description: 'Show emergency switch in burger menu',
    },
    insuranceNumberMask: {
      label: 'Insurance number mask',
      description: 'Use imask for insurance number (see <a href="https://imask.js.org/guide.html#getting-started" target="_blank">documentation</a>)',
    },
    accidentSwitch: {
      label: 'Accident Switch',
      description: 'Show accident switch in burger menu',
    },
    multiHeaderMoreInfo: {
      label: 'Show more info in multi order header',
      description: '',
    },
    reportValueAllAnalyses: {
      label: 'Show report values only if all analyses are found in previous reports',
      description: '',
    },
    basketUnshiftRequirements: {
      label: 'Unshift basket requirements',
      description: 'Newly added requirements will be added on top of basket',
    },
    treatmentReasonDropdown: {
      label: 'Treatment reason as dropdown',
    },
    barcodeFactor: {
      title: 'Barcode Factor',
      defaultFactor: 'Default factor',
      showOriginalCount: {
        label: 'Don´t display factorized count',
        description: '',
      },
      sample: 'Sample',
      addSamples: 'Add Sample',
      factor: 'Factor',
    },
    printerMapping: {
      title: 'Printer Mapping',
      alias: 'Alias',
      override_printer_name: 'Drucker Name',
      override_barcode_printer_name: 'Barcode Drucker Name',
    },
    scheduleTimeEnabled: 'Enable schedule time',
    scheduleTimeInterval: 'Schedule time interval',
    scheduleTimeDefaultEnabled: 'Enable schedule default time',
    scheduleTimeDefault: 'Default schedule time',
    anamnesisShrinkMode: {
      label: 'Anamnesis form shrink mode',
      description: 'Uses smaller buttons, inputs and spacings in anamnesis form',
    },
    bakSettings: {
      title: 'BAK Settings',
      bakColor: 'Background color for microbiological requirements',
      bakFontColor: 'Font color for microbiological requirements',
    },
    burgerMenuPrioritySwitchesSettings: {
      title: 'Burger Menu Priority Switches',
    },
    urgentNotificationSettings: {
      title: 'Urgent Notification Settings',
      mode: {
        call: 'Phone number',
        fax: 'Fax',
        email: 'E-mail',
        deprecatedMode: {
          title: 'Deprecated Mode',
          description: 'Use a dropdown to choose between as agreed with laboratory or the options from above',
        },
      },
    },
    sortPatientsDropdownByName: {
      label: 'Sort Patients in Dropdown By Name',
      description: '',
    },
    sortPoolPatientsByName: {
      label: 'Sort Pool Patients By Name',
      description: '',
    },
    copyToPatientActivationSwitch: {
      label: 'Activate copy to patient activation switch',
      description: '',
    },
  },
  printSettings: {
    groupLabel: {
      barcode: 'Barcode',
      form: 'Form',
    },
    barcodeMode: {
      label: 'Barcode mode',
      selectLabels: {
        [DoctorBarcodeMode.NoBarcodePrint]: 'No Barcode Printing',
        [DoctorBarcodeMode.PrintBarcodes]: 'Print Barcode',
        [DoctorBarcodeMode.PrintGboBarcodes]: 'GBO barcodes',
        [DoctorBarcodeMode.PrintFreeBarcodes]: 'Manually Entered Barcodes',
        [DoctorBarcodeMode.CentralBarcodes]: 'Central Barcodes',
      },
    },
    labelPrinterMapping: {
      groupLabel: 'Label Printer Mapping',
      form: 'Form',
      workstation: 'Workstation',
      printerName: 'Printer Name',
    },
    printRange: 'Print range',
    printRangeMin: 'Print range minimum',
    printRangeMax: 'Print range maximum',
    printPrefix: 'Print prefix',
    printPosition: 'Actual barcode',
    overwrite: 'Overvrite',
    useOwnRange: 'Use own range',
    useLaboratoryGroupRange: 'Use laboratory group range',
    internalPrintUser: 'Internal Print User',
    availableCount: 'Available barcodes',
    nextBarcode: 'Next barcode',
    socketPrint: 'Socket-Print',
    statusHints: {
      ok: 'There are more barcodes available than the minimum value used',
      warning: 'There are fewer barcodes available than the minimum value used',
      error: 'There are fewer barcodes available than the half of the minimum value used',
    },
    resetCentralBarcodes: 'Reset central barcodes',
  },
  laboratoryGroupBarcodeSettings: {
    printRange: 'Print range',
    printRangeMin: 'Print range minimum',
    printRangeMax: 'Print range maximum',
    printPrefix: 'Print prefix',
    printPosition: 'Actual barcode',
    overwrite: 'Overvrite',
  },
  advancedAlphanumericBarcodeSettings: {
    noneAvailable: 'No barcode settings available',
    useState: 'Use state',
    firstPart: 'First part of barcode',
    secondPart: 'Second part of barcode',
    thirdPart: 'Third part of barcode',
    useStateLabels: {
      [AdvancedAlphanumericBarcodeUseState.USE]: 'In use',
      [AdvancedAlphanumericBarcodeUseState.REG]: 'Prepared / Blocked',
    },
  },
  realReRequestSettings: {
    mtlMail: 'MTL E-mail address',
    useLgMail: 'Use laboratory group mail address if a different lab group is set on the form',
    orderTimeframe: 'Order manipulation time window (in days)',
    reRequestLimit: 'Maximum limit in hours',
    reRequestBuffer: 'Execution buffer in minutes',
    reRequestApprove: 'Additional requirements are to be approved',
    blockRealReRequestEmail: 'No email sending for Additional requirements are not to be approved',
    reRequestNoExport: 'No LDT export after additional requirements',
    reRequestLimitSampleDate: 'Calculate the real re-request time limit based on the sample date',
  },
  data: {
    export: {
      favorites: 'Export favorites',
    },
    header: 'Data settings',
    save: 'Save settings',
    tabs: {
      requirements: 'Requirements',
      analyses: 'Analyses',
      materials: 'Materials',
      mainMaterials: 'Main materials',
      orderForms: 'Order forms',
      bakMaterials: 'BAK materials',
      bakAnalyses: 'BAK analyses',
    },
    labels: {
      requirements: {
        controls: {
          edit: {
            label: 'Edit requirement',
            notifications: {
              success: 'Edited requirement successfully',
            },
          },
        },
        columns: {
          requirement: 'Requirement',
          shortName: 'Short Name',
          externalName: 'External Name',
          alias: 'Alias',
          rerequestLimit: 'Rerequest Limit',
        },
      },
      analyses: {
        controls: {
          edit: {
            label: 'Edit analysis',
            notifications: {
              success: 'Edited analysis successfully',
            },
          },
        },
        columns: {
          sampleType: 'Sample Type',
          material: 'Material',
          amount: 'Amount',
        },
      },
      mainMaterials: {
        header: 'Main material settings',
        save: 'Save settings',
        controls: {
          add: {
            label: 'Add main material',
            notifications: {
              success: 'Added main material successfully',
            },
          },
          edit: {
            label: 'Edit main material',
            notifications: {
              success: 'Edited main material successfully',
            },
          },
          delete: {
            label: 'Delete main material',
            notifications: {
              confirm: 'Confirm delete',
              success: 'Deleted main material successfully',
            },
          },
        },
        notifications: {
          success: 'Saved settings successfully',
          error: 'An error occurred',
        },
        create: {
          cardTitle: 'Create main material',
          name: 'Name',
        },
        tabs: {
          detail: 'Detail',
        },
        labels: {
          id: 'Id',
          name: 'Name',
          noImage: 'Image not set',
          sampleImageSmall: 'Small sample image',
          sampleImageLarge: 'Large sample image',
          manufacturer: 'Manufacturer',
          unit: 'Unit',
          quantity: 'Quantity',
          sampleType: 'Sample type',
          substituteSampleTypes: 'Substitute sample types',
          sampleTypeLabel: 'Label',
          order: 'Order',
          laboratoryGroupShortCode: 'Lab group',
          comment: 'Comment',
          notice: 'Notice',
          upload: 'Click to upload',
        },
      },
      materials: {
        controls: {
          edit: {
            label: 'Edit material',
            notifications: {
              success: 'Edited material successfully',
            },
          },
        },
        columns: {
          sampleType: 'Sample Type',
          limit: 'Rerequest Limit',
        },
      },
      bakAnalyses: {
        controls: {
          edit: {
            label: 'Edit analysis',
            notifications: {
              success: 'Edited analysis successfully',
            },
          },
        },
      },
      bakMaterials: {
        controls: {
          edit: {
            label: 'Edit bak material',
            notifications: {
              success: 'Edited bak material successfully',
            },
          },
        },
      },
      orderForms: {
        defaultTab: 'General',
        billingTab: 'Billing',
        priceFactor: 'priceFactor',
        basePrice: 'Base Price',
        name: 'Order form name',
        note: 'Note',
        costUnit: 'Cost unit',
        isBak: 'BAK form',
        isFilterHidden: 'Hidden in filters',
        isDefault: {
          label: 'Default form',
          description: '',
        },
        isPrivate: 'Private form',
        isPool: 'Pool form',
        isPoct: 'Poct form',
        displayEbmPrice: 'Display EBM price',
        isDiagnosesSelect: 'Requirement diagnoses',
        invoiceToChangeable: 'Invoice to changeable',
        defaultInvoiceTo: 'Default invoice to',
        hasDynProbeType: 'Dynamic probe type',
        isDynamicMaterial: 'Dynamic materials',
        laborGroup: 'Laboratory Group',
        splitMode: {
          label: 'Split mode',
          values: {
            [SplitMode.Never]: 'never',
            [SplitMode.Always]: 'always',
            [SplitMode.Conditional]: 'conditional',
          },
        },
        formType: {
          label: 'Special form type (KBV Muster)',
          values: {
            [FormType.General]: 'None',
            [FormType.KBVMuster10]: 'KBV-Muster 10',
            [FormType.KBVMuster10A]: 'KBV-Muster 10A',
            [FormType.KBVMuster10C]: 'KBV-Muster 10C',
            [FormType.KBVMusterOegd]: 'KBV-Muster OEGD',
            [FormType.Custom]: 'Custom',
          },
        },
        controls: {
          add: {
            label: 'Add order form',
          },
          edit: {
            label: 'Edit order form',
          },
          delete: {
            label: 'Delete order form',
            confirm: 'Confirm delete',
          },
        },
      },
    },
    notifications: {
      success: 'Saved settings successfully',
      error: 'An error occurred',
    },
    controls: {
      search: 'Search',
      requirement: {
        edit: {
          label: 'Edit requirement',
          form: {
            tabs: {
              general: 'General',
              price: 'Price',
              others: 'Others',
              hint: 'Hint',
              additionalText: 'Additional text',
            },
            id: 'Id',
            maxRequirements: 'Max requirements',
            shortName: 'Short name',
            externalName: 'External name',
            duration: 'Duration',
            priority: 'Priority',
            lisExport: 'LIS export',
            isCharged: 'Charge is active',
            isEbmCharged: 'Is EBM charged',
            laboratoryGroup: 'Laboratory group',
            freeTextAllowed: 'Freetext allowed',
            freeTextMandatory: 'Freetext mandatory',
            freeTextAsDate: 'Freetext as date',
            freeTextKey: 'Freetext LDT key',
            freeTextPreappendValue: 'Freetext preappand value',
            alias: 'Alias',
            longName: 'Name',
            limit: 'Limit',
            infoUrl: 'Info Url',
            additionalTextInfo: 'Additional text is shown directly in the order wizard list',
            hintInfo: 'Hints are shown in in order wizard popover',
            examinationDuration: 'Examination Duration',
            priceGroups: {
              group1: {
                price: 'Price 1',
                maximumPrice: 'Maximum price 1',
                maximumPriceComment: 'Maximum price comment 1',
              },
              group2: {
                price: 'Price 2',
                maximumPrice: 'Maximum price 2',
                maximumPriceComment: 'Maximum price comment 2',
              },
              group3: {
                price: 'Price 3',
                maximumPrice: 'Maximum price 3',
                maximumPriceComment: 'Maximum price comment 3',
              },
              group4: {
                price: 'Price 4',
                maximumPrice: 'Maximum price 4',
                maximumPriceComment: 'Maximum price comment 4',
              },
              group5: {
                price: 'Price 5',
                maximumPrice: 'Maximum price 5',
                maximumPriceComment: 'Maximum price comment 5',
              },
            },
            ebmPrice: 'EBM price',
            notifications: {
              success: 'Updated requirement successfully',
            },
            others: {
              requirementReplace: {
                groupLabel: 'Requiremenet Replace Method',
                buttonLabel: 'Replace Requirement Associations',
                modalTitle: 'Enter a unique requirement short name (e.g. CHOL)',
                modalDescription: 'This function allows you to quickly and easily manipulate the current requirement assignment. By entering the unique abbreviation of the replacement requirement, all assignments of the current requirement are cancelled and assigned to the replacement requirement. The following assignments are adjusted: Slips, Quick Selections, Requirement Groups, Group Slip, Rules. ',
                success: 'Requirement associations successfully replaced',
                error: 'An error occurred while replacing requirement associations',
              },
              requirementFieldsOutput: {
                groupLabel: 'Requiremenet Fields Output',
              },
            },
          },
        },
        delete: {
          label: 'Delete requirement',
          notifications: {
            confirm: 'Confirm delete',
            success: 'Deleted requirement successfully',
          },
        },
        add: {
          label: 'Add requirement',
          notifications: {
            success: 'Added requirement successfully',
          },
        },
      },
      analyses: {
        edit: {
          form: {
            amount: 'Amount',
          },
        },
      },
      bakAnalyses: {
        edit: {
          form: {
            longName: 'Long name',
            shortName: 'Short name',
            alias: 'Alias',
            order: 'Order',
            askAnalysisFreeText: 'Ask analysis freetext',
          },
        },
      },
      materials: {
        edit: {
          form: {
            sampleType: 'Sample Type',
            limit: 'Rerequest Limit',
          },
        },
      },
      bakMaterials: {
        edit: {
          form: {
            matid: 'Material ID',
            globalText: 'Material ID',
            askLeftRight: 'Ask left/right',
            askIntraoperative: 'Ask intraoperativ',
            askOrigin: 'Ask origin',
          },
        },
      },
      pagination: {
        loadNext: 'Load next',
        loadPrevious: 'Load previous',
      },
      filter: {
        common: {
          laboratoryGroup: 'Group',
          submitter: 'Submitter',
          source: 'Source',
          consistency: 'Consistency',
          sampleType: 'Sample Type',
          selectOne: 'Select',
        },
        requirements: {
          material: {
            _1: 'Without material',
            _2: 'Without main material',
            _3: 'Unassigned',
          },
          source: {
            _1: 'Self defined',
            _2: 'Imported',
          },
        },
        analyses: {
          _1: 'Without material',
          _2: 'Without main material',
        },
        materials: {
          _1: 'With sample type',
          _2: 'Without sample type',
        },
      },
    },
  },
};

/**
 * ATTENTION!!!
 *
 * DO NOT MODIFY THIS FILE BECAUSE IT WAS GENERATED AUTOMATICALLY,
 * Instead, run 'yarn api' to regenerate this file.
 */

import { Client } from 'providers/ApiProvider/client';
import { ApiDefinition } from 'interfaces/api';

export const api = (client: Client): ApiDefinition => ({

  authentication: {
    getUserDetails: async (data, config) => await client.get('/api/auth', data, config),
    login: async (data, config) => await client.post('/api/auth', data, config),
    logout: async (data, config) => await client.delete('/api/auth', data, config),
    createToken: async (data, config) => await client.post('/api/auth/token', data, config),
    invalidateToken: async (data, config) => await client.delete('/api/auth/token', data, config),
    verifyToken: async (data, config) => await client.get('/api/auth/token', data, config),
    createLegacyToken: async (data, config) => await client.post('/api/auth/token/legacy', data, config),
    exchangeToken: async (data, config) => await client.post('/api/auth/token/exchange', data, config),
    createLabconnectRegistrationToken: async (data, config) => await client.post('/api/auth/token/labconnect', data, config),
    requestPasswordReset: async (data, config) => await client.delete('/api/auth/password', data, config),
    changePassword: async (data, config) => await client.patch('/api/auth/password', data, config),
    resetPassword: async (data, config) => await client.patch('/api/auth/password/reset', data, config),
    checkPassword: async (data, config) => await client.post('/api/auth/password/check', data, config),
    initializeTwoFactorAuthentication: async (data, config) => await client.get('/api/auth/two-factor', data, config),
    enableTwoFactorAuthentication: async (data, config) => await client.post('/api/auth/two-factor', data, config),
    disableTwoFactorAuthentication: async (data, config) => await client.delete('/api/auth/two-factor', data, config),
  },

  globals: {
    getUserConfig: async (data, config) => await client.get('/api/config', data, config),
    updateConfig: async (data, config) => await client.put('/api/config', data, config),
    getAutocomplete: async (data, config) => await client.get('/api/autocomplete', data, config),
    getServerEnv: async (data, config) => await client.get('/api/env', data, config),
    getVersionBadge: async (data, config) => await client.get('/api/version', data, config),
    getWhatsappQrCode: async (data, config) => await client.get('/api/whatsapp/{id}', data, config),
    getBuildInfo: async (data, config) => await client.get('/api/build', data, config),
    sendContactForm: async (data, config) => await client.post('/api/contact', data, config),
    getContactInfo: async (data, config) => await client.get('/api/contact', data, config),
    getPrivacyStatement: async (data, config) => await client.get('/api/privacy', data, config),
    getTermsAndConditions: async (data, config) => await client.get('/api/toc', data, config),
  },

  ordersDashboard: {
    getDevelopment: async (data, config) => await client.get('/api/dashboard/orders/development', data, config),
    getTopRequests: async (data, config) => await client.get('/api/dashboard/orders/topRequests', data, config),
    getTopUsers: async (data, config) => await client.get('/api/dashboard/orders/topUsers', data, config),
  },

  reportsDashboard: {
    getDemographic: async (data, config) => await client.get('/api/dashboard/reports/demographic', data, config),
    getTopGroups: async (data, config) => await client.get('/api/dashboard/reports/groups', data, config),
    getTypes: async (data, config) => await client.get('/api/dashboard/reports/types', data, config),
  },

  exchangeDashboard: {
    getFileTypesOverview: async (data, config) => await client.get('/api/dashboard/exchange/fileTypes', data, config),
    getExportHistory: async (data, config) => await client.get('/api/dashboard/exchange/exportHistory', data, config),
    getLaboratoryGroupDistribution: async (data, config) => await client.get('/api/dashboard/exchange/labGroupDistribution', data, config),
  },

  dashboard: {
    getDefaultDashboardData: async (data, config) => await client.get('/api/dashboard', data, config),
    getAdminStats: async (data, config) => await client.get('/api/dashboard/admin', data, config),
  },

  users: {
    activateUser: async (data, config) => await client.post('/api/users/activate', data, config),
    reactivateUser: async (data, config) => await client.post('/api/users/reactivate', data, config),
    deactivateUser: async (data, config) => await client.post('/api/users/deactivate', data, config),
    resendWelcomeMail: async (data, config) => await client.post('/api/users/resendMail', data, config),
    enableDebugForUser: async (data, config) => await client.post('/api/users/enableDebug', data, config),
    setUserApiDocs: async (data, config) => await client.post('/api/users/apiDocs', data, config),
    disableTwoFactorAuthForUser: async (data, config) => await client.delete('/api/users/two-factor', data, config),
    changeUsername: async (data, config) => await client.put('/api/users/username', data, config),
    changeUserPassword: async (data, config) => await client.put('/api/users/password', data, config),
    getUserLog: async (data, config) => await client.get('/api/users/logs', data, config),
  },

  profile: {
    getUserProfile: async (data, config) => await client.get('/api/users/profile', data, config),
    updateUserProfile: async (data, config) => await client.patch('/api/users/profile', data, config),
    updateAvatarUserProfile: async (data, config) => await client.patch('/api/users/profile/avatar', client.createFormData(data), config),
    getUserAvatar: async (data, config) => await client.get('/api/users/profile/avatar/{id}', data, config),
    getVcardQrCode: async (data, config) => await client.get('/api/users/profile/vcard/qr/{id}/{type}', data, config),
    getVcard: async (data, config) => await client.get('/api/users/profile/vcard/{id}/{type}', data, config),
  },

  devices: {
    listDevices: async (data, config) => await client.get('/api/users/devices', data, config),
    createDevice: async (data, config) => await client.post('/api/users/devices', data, config),
    deleteDevice: async (data, config) => await client.delete('/api/users/devices/{id}', data, config),
    approveDevice: async (data, config) => await client.put('/api/users/devices/{id}/approve', data, config),
    denyDevice: async (data, config) => await client.put('/api/users/devices/{id}/deny', data, config),
    revokeDevice: async (data, config) => await client.put('/api/users/devices/{id}/revoke', data, config),
    setDeviceNote: async (data, config) => await client.put('/api/users/devices/{id}/note', data, config),
    setPushToken: async (data, config) => await client.put('/api/users/devices/pushToken', data, config),
    createDeviceToken: async (data, config) => await client.post('/api/users/devices/token', data, config),
  },

  administrators: {
    listAdministrators: async (data, config) => await client.get('/api/users/administrators', data, config),
    createAdministrator: async (data, config) => await client.post('/api/users/administrators', data, config),
    readAdministrator: async (data, config) => await client.get('/api/users/administrators/{suid}', data, config),
    updateAdministrator: async (data, config) => await client.patch('/api/users/administrators/{suid}', data, config),
    removeAdministrator: async (data, config) => await client.delete('/api/users/administrators/{suid}', data, config),
  },

  doctorHierarchy: {
    getDoctorHierarchyList: async (data, config) => await client.get('/api/users/doctors/hierarchy', data, config),
    assignDoctorHierarchyUser: async (data, config) => await client.put('/api/users/doctors/hierarchy', data, config),
    createDoctorHierarchyUser: async (data, config) => await client.post('/api/users/doctors/hierarchy', data, config),
    getDoctorHierarchyTree: async (data, config) => await client.get('/api/users/doctors/hierarchy/tree', data, config),
    readDoctorHierarchy: async (data, config) => await client.get('/api/users/doctors/hierarchy/{id}', data, config),
    removeDoctorHierarchyUser: async (data, config) => await client.delete('/api/users/doctors/hierarchy/{id}', data, config),
    updateDoctorHierarchyUser: async (data, config) => await client.patch('/api/users/doctors/hierarchy/{id}', data, config),
  },

  doctors: {
    listDoctors: async (data, config) => await client.get('/api/users/doctors', data, config),
    createDoctor: async (data, config) => await client.post('/api/users/doctors', data, config),
    readDoctor: async (data, config) => await client.get('/api/users/doctors/{aid}', data, config),
    updateDoctor: async (data, config) => await client.patch('/api/users/doctors/{aid}', data, config),
    deleteDoctor: async (data, config) => await client.delete('/api/users/doctors/{aid}', data, config),
    getDoctorgroupsForDoctor: async (data, config) => await client.get('/api/users/doctors/{aid}/groups', data, config),
    setDoctorgroupsForDoctor: async (data, config) => await client.patch('/api/users/doctors/{aid}/groups', data, config),
  },

  doctorDevices: {
    listDoctorDevices: async (data, config) => await client.get('/api/users/doctors/{aid}/devices', data, config),
    updateDoctorDevices: async (data, config) => await client.patch('/api/users/doctors/{aid}/devices', data, config),
    updateDoctorDevice: async (data, config) => await client.patch('/api/users/doctors/{aid}/devices/{id}', data, config),
    deleteDoctorDevice: async (data, config) => await client.delete('/api/users/doctors/{aid}/devices/{id}', data, config),
  },

  laboratories: {
    listLaboratories: async (data, config) => await client.get('/api/users/laboratories', data, config),
    createLaboratory: async (data, config) => await client.post('/api/users/laboratories', data, config),
    readLaboratory: async (data, config) => await client.get('/api/users/laboratories/{lid}', data, config),
    updateLaboratory: async (data, config) => await client.patch('/api/users/laboratories/{lid}', data, config),
    uploadLaboratoryLogo: async (data, config) => await client.patch('/api/users/laboratories/{lid}/logo', client.createFormData(data), config),
    getLaboratoryDetails: async (data, config) => await client.get('/api/users/laboratories/{lid}/details', data, config),
    updateLaboratoryDetails: async (data, config) => await client.patch('/api/users/laboratories/{lid}/details', data, config),
    getLaboratoryPermissions: async (data, config) => await client.get('/api/users/laboratories/{lid}/permissions', data, config),
    updateLaboratoryPermissions: async (data, config) => await client.patch('/api/users/laboratories/{lid}/permissions', data, config),
  },

  laboratoryUsers: {
    listLaboratoryUsers: async (data, config) => await client.get('/api/users/laboratoryusers', data, config),
    createLaboratoryUser: async (data, config) => await client.post('/api/users/laboratoryusers', data, config),
    readLaboratoryUser: async (data, config) => await client.get('/api/users/laboratoryusers/{luid}', data, config),
    updateLaboratoryUser: async (data, config) => await client.patch('/api/users/laboratoryusers/{luid}', data, config),
    deleteLaboratoryUser: async (data, config) => await client.delete('/api/users/laboratoryusers/{luid}', data, config),
    getLaboratoryUserPermissions: async (data, config) => await client.get('/api/users/laboratoryusers/{luid}/permissions', data, config),
    updateLaboratoryUserPermissions: async (data, config) => await client.patch('/api/users/laboratoryusers/{luid}/permissions', data, config),
  },

  patients: {
    listPatients: async (data, config) => await client.get('/api/users/patients', data, config),
    createPatient: async (data, config) => await client.post('/api/users/patients', data, config),
    readPatient: async (data, config) => await client.get('/api/users/patients/{pid}', data, config),
    updatePatient: async (data, config) => await client.patch('/api/users/patients/{pid}', data, config),
    removePatient: async (data, config) => await client.delete('/api/users/patients/{pid}', data, config),
    readPatientByIdentification: async (data, config) => await client.get('/api/users/patients/ident/{pident}', data, config),
  },

  reports: {
    listReports: async (data, config) => await client.get('/api/reports', data, config),
    deleteAllReportsForAUser: async (data, config) => await client.delete('/api/reports', data, config),
    getReportsConfig: async (data, config) => await client.get('/api/reports/config', data, config),
    getQuickAccessPdf: async (data, config) => await client.post('/api/reports/pdf', data, config),
    registerQuickAccessEmail: async (data, config) => await client.post('/api/reports/pdf/notify', data, config),
    readReportByGuid: async (data, config) => await client.get('/api/reports/guid/{guid}', data, config),
    notifyReportByGuid: async (data, config) => await client.post('/api/reports/guid/{guid}', data, config),
    getReportPdfByGuid: async (data, config) => await client.post('/api/reports/guid/{guid}/pdf', data, config),
    importIdexxReport: async (data, config) => await client.post('/api/reports/idexx', data, config),
    listReportsForAis: async (data, config) => await client.get('/api/reports/ais', data, config),
    readReportForAis: async (data, config) => await client.get('/api/reports/ais/{bid}', data, config),
    setAllReportsRead: async (data, config) => await client.post('/api/reports/read/{aid}', data, config),
    createFilterProfile: async (data, config) => await client.post('/api/reports/filters', data, config),
    updateFilterProfile: async (data, config) => await client.patch('/api/reports/filters/{pid}', data, config),
    deleteFilterProfile: async (data, config) => await client.delete('/api/reports/filters/{pid}', data, config),
    toggleFilterProfileFavorite: async (data, config) => await client.post('/api/reports/filters/{pid}/favorite', data, config),
    readReport: async (data, config) => await client.get('/api/reports/{bid}', data, config),
    deleteReport: async (data, config) => await client.delete('/api/reports/{bid}', data, config),
    getReportPdf: async (data, config) => await client.get('/api/reports/{bid}/pdf', data, config),
    getReportOriginalPdfImages: async (data, config) => await client.get('/api/reports/{bid}/original-pdf-images', data, config),
    setReadReport: async (data, config) => await client.post('/api/reports/{bid}/read', data, config),
    getReportElpho: async (data, config) => await client.get('/api/reports/{bid}/elpho', data, config),
    getReportHistory: async (data, config) => await client.get('/api/reports/{bid}/history', data, config),
    resetReportViewCount: async (data, config) => await client.delete('/api/reports/{bid}/view', data, config),
    sendReport: async (data, config) => await client.post('/api/reports/{bid}/send', data, config),
    sendReportApi: async (data, config) => await client.post('/api/reports/{bid}/sendReportApi', data, config),
    contactLaboratory: async (data, config) => await client.post('/api/reports/{bid}/contact_laboratory', data, config),
    unrealRerequest: async (data, config) => await client.post('/api/reports/{bid}/rerequest', data, config),
    pinReport: async (data, config) => await client.post('/api/reports/{bid}/pin', data, config),
    unpinReport: async (data, config) => await client.delete('/api/reports/{bid}/pin', data, config),
    listReportComments: async (data, config) => await client.get('/api/reports/{bid}/comment', data, config),
    createReportComment: async (data, config) => await client.post('/api/reports/{bid}/comment', data, config),
    deleteReportComment: async (data, config) => await client.delete('/api/reports/{bid}/comment/{id}', data, config),
    listReportAttachments: async (data, config) => await client.get('/api/reports/{bid}/attachments', data, config),
    uploadAttachment: async (data, config) => await client.post('/api/reports/{bid}/attachments', client.createFormData(data), config),
    downloadAttachment: async (data, config) => await client.get('/api/reports/{bid}/attachments/{anid}', data, config),
    deleteAttachment: async (data, config) => await client.delete('/api/reports/{bid}/attachments/{anid}', data, config),
    getReportAccessLog: async (data, config) => await client.get('/api/reports/{bid}/logs/access', data, config),
    getReportChangeLog: async (data, config) => await client.get('/api/reports/{bid}/logs/change', data, config),
    unlockReport: async (data, config) => await client.delete('/api/reports/{bid}/lock', data, config),
  },

  reportsSettings: {
    readGeneralReportsConfig: async (data, config) => await client.get('/api/reports/settings/general', data, config),
    updateGeneralReportsConfig: async (data, config) => await client.put('/api/reports/settings/general', data, config),
    readUserReportsConfig: async (data, config) => await client.get('/api/reports/settings/user/{entityId}/{type}', data, config),
    updateUserReportsConfig: async (data, config) => await client.put('/api/reports/settings/user/{entityId}/{type}', data, config),
    resetUserReportsPreferences: async (data, config) => await client.delete('/api/reports/settings/user/{entityId}/{type}', data, config),
    resetUserTypeReportsPreferences: async (data, config) => await client.delete('/api/reports/settings/user/{type}', data, config),
    getReportAnalysesSettings: async (data, config) => await client.get('/api/reports/settings/analyses', data, config),
    updateReportAnalysesSettings: async (data, config) => await client.put('/api/reports/settings/analyses', data, config),
    getBlacklistSettings: async (data, config) => await client.get('/api/reports/settings/blacklist', data, config),
    updateBlacklistSettings: async (data, config) => await client.put('/api/reports/settings/blacklist', data, config),
    getCustomPushSettings: async (data, config) => await client.get('/api/reports/settings/custompush', data, config),
    updateCustomPushSettings: async (data, config) => await client.put('/api/reports/settings/custompush', data, config),
    getOriginalReportSettings: async (data, config) => await client.get('/api/reports/settings/originalreport', data, config),
    updateOriginalReportSettings: async (data, config) => await client.put('/api/reports/settings/originalreport', data, config),
    exportCustomSortCsv: async (data, config) => await client.get('/api/reports/settings/customsort/export', data, config),
    listCustomSortCsv: async (data, config) => await client.get('/api/reports/settings/customsort', data, config),
    importCustomSortCsv: async (data, config) => await client.post('/api/reports/settings/customsort', client.createFormData(data), config),
    readReportQuickAccessConfig: async (data, config) => await client.get('/api/reports/settings/quickAccess', data, config),
    updateReportQuickAccessConfig: async (data, config) => await client.put('/api/reports/settings/quickAccess', data, config),
    readReportQuickScanConfig: async (data, config) => await client.get('/api/reports/settings/quickScan', data, config),
    updateReportQuickScanConfig: async (data, config) => await client.put('/api/reports/settings/quickScan', data, config),
  },

  orders: {
    getOrdersConfig: async (data, config) => await client.get('/api/orders/config', data, config),
    listOrders: async (data, config) => await client.get('/api/orders', data, config),
    upsertOrders: async (data, config) => await client.post('/api/orders', data, config),
    deleteOrder: async (data, config) => await client.delete('/api/orders', data, config),
    scheduleOrders: async (data, config) => await client.patch('/api/orders/schedule', data, config),
    executeOrders: async (data, config) => await client.patch('/api/orders/execute', data, config),
    preprintOrders: async (data, config) => await client.patch('/api/orders/preprint', data, config),
    startEditOrders: async (data, config) => await client.patch('/api/orders/startEdit', data, config),
    cancelEditOrders: async (data, config) => await client.patch('/api/orders/cancelEdit', data, config),
    cancelOrders: async (data, config) => await client.patch('/api/orders/cancel', data, config),
    cancelOrderRequirement: async (data, config) => await client.delete('/api/orders/{orderId}/requirement', data, config),
    uncancelOrderRequirement: async (data, config) => await client.post('/api/orders/{orderId}/requirement', data, config),
    softDeleteOrder: async (data, config) => await client.delete('/api/orders/soft', data, config),
    signOrders: async (data, config) => await client.post('/api/orders/sign', data, config),
    exportWithoutPdf: async (data, config) => await client.post('/api/orders/export/without_pdf', data, config),
    getOrderByCaseNumber: async (data, config) => await client.get('/api/orders/caseNumber/{caseNumber}', data, config),
    getOrderByExttnr: async (data, config) => await client.get('/api/orders/exttnr/{exttnr}', data, config),
    getOrdersByPatient: async (data, config) => await client.get('/api/orders/patient/{type}/{id}', data, config),
    readOrderPool: async (data, config) => await client.get('/api/orders/pool/{id}', data, config),
    readOrder: async (data, config) => await client.get('/api/orders/{id}', data, config),
    getOrderChangeLog: async (data, config) => await client.get('/api/orders/{pool_id}/logs', data, config),
    changeOrdersWorkstation: async (data, config) => await client.post('/api/orders/workstation', data, config),
  },

  transportationList: {
    createTransportation: async (data, config) => await client.post('/api/orders/transportation', data, config),
    listTransportationHistory: async (data, config) => await client.get('/api/orders/transportation', data, config),
    exportTransportation: async (data, config) => await client.get('/api/orders/transportation/export', data, config),
  },

  journal: {
    exportJournalPdf: async (data, config) => await client.get('/api/orders/journal/pdf', data, config),
  },

  orderSettings: {
    readGeneralOrdersConfig: async (data, config) => await client.get('/api/orders/settings/general', data, config),
    updateGeneralOrdersConfig: async (data, config) => await client.put('/api/orders/settings/general', data, config),
    readUserOrdersConfig: async (data, config) => await client.get('/api/orders/settings/user/{entityId}/{type}', data, config),
    updateUserOrdersConfig: async (data, config) => await client.put('/api/orders/settings/user/{entityId}/{type}', data, config),
    resetUserOrdersPreferences: async (data, config) => await client.delete('/api/orders/settings/user/{entityId}/{type}', data, config),
    resetUserTypeOrdersPreferences: async (data, config) => await client.delete('/api/orders/settings/user/{type}', data, config),
    getOrderExportSettings: async (data, config) => await client.get('/api/orders/settings/export', data, config),
    updateOrderExportSettings: async (data, config) => await client.patch('/api/orders/settings/export', data, config),
    listSamples: async (data, config) => await client.get('/api/orders/settings/samples', data, config),
    createSample: async (data, config) => await client.post('/api/orders/settings/samples', client.createFormData(data), config),
    listManufacturers: async (data, config) => await client.get('/api/orders/settings/samples/manufacturers', data, config),
    readSample: async (data, config) => await client.get('/api/orders/settings/samples/{id}', data, config),
    deleteSample: async (data, config) => await client.delete('/api/orders/settings/samples/{id}', data, config),
    updateSample: async (data, config) => await client.patch('/api/orders/settings/samples/{id}', client.createFormData(data), config),
    listAnamnesisQuestions: async (data, config) => await client.get('/api/orders/settings/anamnesis/questions', data, config),
    readAnamnesisQuestion: async (data, config) => await client.get('/api/orders/settings/anamnesis/questions/{id}', data, config),
    listOrderRules: async (data, config) => await client.get('/api/orders/settings/rules', data, config),
    createOrderRule: async (data, config) => await client.post('/api/orders/settings/rules', data, config),
    listOrderRuleGroups: async (data, config) => await client.get('/api/orders/settings/rules/groups', data, config),
    readOrderRule: async (data, config) => await client.get('/api/orders/settings/rules/{id}', data, config),
    deleteOrderRule: async (data, config) => await client.delete('/api/orders/settings/rules/{id}', data, config),
    updateOrderRule: async (data, config) => await client.patch('/api/orders/settings/rules/{id}', data, config),
    listOrderForms: async (data, config) => await client.get('/api/orders/settings/forms', data, config),
    createOrderForm: async (data, config) => await client.post('/api/orders/settings/forms', data, config),
    readOrderForm: async (data, config) => await client.get('/api/orders/settings/forms/{id}', data, config),
    updateOrderForm: async (data, config) => await client.patch('/api/orders/settings/forms/{id}', data, config),
    deleteOrderForm: async (data, config) => await client.delete('/api/orders/settings/forms/{id}', data, config),
    readAnalysis: async (data, config) => await client.get('/api/orders/settings/analyses/{id}', data, config),
    updateAnalysis: async (data, config) => await client.patch('/api/orders/settings/analyses/{id}', data, config),
    listAnalyses: async (data, config) => await client.get('/api/orders/settings/analyses', data, config),
    updateAnalyses: async (data, config) => await client.patch('/api/orders/settings/analyses', data, config),
    getEbmList: async (data, config) => await client.get('/api/orders/settings/analyses/settings/ebm', data, config),
    readMaterial: async (data, config) => await client.get('/api/orders/settings/materials/{id}', data, config),
    updateMaterial: async (data, config) => await client.patch('/api/orders/settings/materials/{id}', data, config),
    listMaterials: async (data, config) => await client.get('/api/orders/settings/materials', data, config),
    updateMaterials: async (data, config) => await client.patch('/api/orders/settings/materials', data, config),
    readRequirement: async (data, config) => await client.get('/api/orders/settings/requirements/{id}', data, config),
    deleteRequirement: async (data, config) => await client.delete('/api/orders/settings/requirements/{id}', data, config),
    updateRequirement: async (data, config) => await client.patch('/api/orders/settings/requirements/{id}', data, config),
    listRequirements: async (data, config) => await client.get('/api/orders/settings/requirements', data, config),
    createRequirement: async (data, config) => await client.post('/api/orders/settings/requirements', data, config),
    updateRequirements: async (data, config) => await client.patch('/api/orders/settings/requirements', data, config),
    replaceRequirementAssociations: async (data, config) => await client.post('/api/orders/settings/requirements/{id}/replace', data, config),
    listRequirementLaboratoryGroups: async (data, config) => await client.get('/api/orders/settings/requirement-laboratory-groups', data, config),
    listRequirementSubmitters: async (data, config) => await client.get('/api/orders/settings/requirement-submitters', data, config),
    overtakeOaSettingsForDoctor: async (data, config) => await client.post('/api/orders/settings/overtake/{templateAid}', data, config),
    updateGroupFormsOrder: async (data, config) => await client.put('/api/orders/settings/groupForms/{aid}', data, config),
    readBakAnalysis: async (data, config) => await client.get('/api/orders/settings/bak-analyses/{shortName}', data, config),
    updateBakAnalysis: async (data, config) => await client.patch('/api/orders/settings/bak-analyses/{shortName}', data, config),
    listBakAnalyses: async (data, config) => await client.get('/api/orders/settings/bak-analyses', data, config),
    listBakMaterials: async (data, config) => await client.get('/api/orders/settings/bak-materials', data, config),
    readBakMaterial: async (data, config) => await client.get('/api/orders/settings/bak-materials/{matid}', data, config),
    updateBakMaterial: async (data, config) => await client.patch('/api/orders/settings/bak-materials/{matid}', data, config),
    exportDoctorsFavorites: async (data, config) => await client.get('/api/orders/settings/data-export/favorites', data, config),
  },

  gdtPatient: {
    listGdtPatients: async (data, config) => await client.get('/api/orders/patients', data, config),
    createOrUpdateGdtPatient: async (data, config) => await client.put('/api/orders/patients', data, config),
    readGdtPatients: async (data, config) => await client.get('/api/orders/patients/{aid}/multiple', data, config),
    readGdtPatient: async (data, config) => await client.get('/api/orders/patients/{aid}/{apgid}', data, config),
    readGdtPatientFromTimestamp: async (data, config) => await client.get('/api/orders/patients/{aid}/updated/{updated_at}', data, config),
    listGdtPatientGroups: async (data, config) => await client.get('/api/orders/patients/groups', data, config),
    getPatientReports: async (data, config) => await client.get('/api/orders/patients/reports/{type}/{id}', data, config),
  },

  requirements: {
    listDoctorRequirements: async (data, config) => await client.get('/api/orders/requirements', data, config),
    pinRequirement: async (data, config) => await client.post('/api/orders/requirements/pin', data, config),
    unpinRequirement: async (data, config) => await client.delete('/api/orders/requirements/pin', data, config),
    getRequirementsAutocomplete: async (data, config) => await client.get('/api/orders/requirements/autocomplete', data, config),
    readRequirement: async (data, config) => await client.get('/api/orders/requirements/{id}', data, config),
  },

  samples: {
    getSampleImage: async (data, config) => await client.get('/api/orders/samples/{id}/image/{type}', data, config),
  },

  orderWizard: {
    getAnamnesisQuestionTree: async (data, config) => await client.get('/api/orders/wizard/anamnesis', data, config),
    getBasket: async (data, config) => await client.post('/api/orders/wizard/basket/{aid}', data, config),
    getSettings: async (data, config) => await client.get('/api/orders/wizard/settings', data, config),
  },

  orderWizardAustriaSettings: {
    getAustriaHvCodes: async (data, config) => await client.get('/api/orders/wizard/austria/hvcodes', data, config),
    getAustriaHvCode: async (data, config) => await client.get('/api/orders/wizard/austria/hvcodes/{code}', data, config),
    getAustriaInsuranceCategories: async (data, config) => await client.get('/api/orders/wizard/austria/vcats', data, config),
  },

  profiles: {
    copyProfiles: async (data, config) => await client.post('/api/orders/profiles/{aid}/copy', data, config),
    pdfExportProfiles: async (data, config) => await client.get('/api/orders/profiles/{aid}/pdfexport', data, config),
    createProfile: async (data, config) => await client.post('/api/orders/profiles/{aid}', data, config),
    listDoctorProfiles: async (data, config) => await client.get('/api/orders/profiles/{aid}', data, config),
    batchUpdateProfiles: async (data, config) => await client.put('/api/orders/profiles/{aid}', data, config),
    readProfile: async (data, config) => await client.get('/api/orders/profiles/{id}/{aid}', data, config),
    updateProfile: async (data, config) => await client.put('/api/orders/profiles/{id}/{aid}', data, config),
  },

  completion: {
    complete: async (data, config) => await client.post('/api/orders/completion', data, config),
  },

  realReRequest: {
    readReRequestDetails: async (data, config) => await client.get('/api/orders/rerequest/{id}', data, config),
    startRealReRequest: async (data, config) => await client.patch('/api/orders/rerequest', data, config),
    sendOffRealReRequest: async (data, config) => await client.patch('/api/orders/rerequest/sendOff', data, config),
    cancelRealReRequest: async (data, config) => await client.patch('/api/orders/rerequest/cancel', data, config),
    acceptAdditionalRequirements: async (data, config) => await client.patch('/api/orders/rerequest/accept', data, config),
  },

  print: {
    printBarcode: async (data, config) => await client.post('/api/orders/{id}/print/barcode', data, config),
    expendExtraBarcode: async (data, config) => await client.post('/api/orders/{id}/print/expend', data, config),
    printOrderForm: async (data, config) => await client.get('/api/orders/{id}/print/orderform', data, config),
  },

  messageCenter: {
    listMessageThreads: async (data, config) => await client.get('/api/messages/threads', data, config),
    createMessageThread: async (data, config) => await client.post('/api/messages/threads', data, config),
    readMessageThread: async (data, config) => await client.get('/api/messages/threads/{thread_id}', data, config),
    deleteMessageThread: async (data, config) => await client.delete('/api/messages/threads/{thread_id}', data, config),
    listMessages: async (data, config) => await client.get('/api/messages/threads/{thread_id}/messages', data, config),
    createMessage: async (data, config) => await client.post('/api/messages/threads/{thread_id}/messages', data, config),
    readMessage: async (data, config) => await client.get('/api/messages/threads/{thread_id}/messages/{message_id}', data, config),
    starMessageThread: async (data, config) => await client.post('/api/messages/threads/{thread_id}/star', data, config),
    unstarMessageThread: async (data, config) => await client.delete('/api/messages/threads/{thread_id}/star', data, config),
    listUsersToBeMessaged: async (data, config) => await client.get('/api/messages/availableUsers', data, config),
  },

  authSettings: {
    readAuthConfig: async (data, config) => await client.get('/api/settings/auth', data, config),
    updateAuthConfig: async (data, config) => await client.put('/api/settings/auth', data, config),
  },

  linksSettings: {
    readLinksConfig: async (data, config) => await client.get('/api/settings/links', data, config),
    updateLinksConfig: async (data, config) => await client.put('/api/settings/links', data, config),
  },

  pdfSettings: {
    upsertPdfLink: async (data, config) => await client.post('/api/settings/links/pdf', client.createFormData(data), config),
    listPdfLinks: async (data, config) => await client.get('/api/settings/links/pdf', data, config),
    downloadPdfLink: async (data, config) => await client.get('/api/settings/links/pdf/{id}', data, config),
    deletePdfLink: async (data, config) => await client.delete('/api/settings/links/pdf/{id}', data, config),
  },

  products: {
    createProduct: async (data, config) => await client.post('/api/settings/products/{product}', data, config),
    removeProduct: async (data, config) => await client.delete('/api/settings/products/{product}', data, config),
    updateProduct: async (data, config) => await client.patch('/api/settings/products/{product}', data, config),
  },

  features: {
    createFeature: async (data, config) => await client.post('/api/settings/products/{product}/features/{feature}', data, config),
    updateFeature: async (data, config) => await client.patch('/api/settings/products/{product}/features/{feature}', data, config),
    removeFeature: async (data, config) => await client.delete('/api/settings/products/{product}/features/{feature}', data, config),
  },

  laboratoryGroups: {
    listLaboratoryGroups: async (data, config) => await client.get('/api/settings/laboratoryGroups', data, config),
    createLaboratoryGroup: async (data, config) => await client.post('/api/settings/laboratoryGroups', data, config),
    readLaboratoryGroup: async (data, config) => await client.get('/api/settings/laboratoryGroups/{grpid}', data, config),
    deleteLaboratoryGroup: async (data, config) => await client.delete('/api/settings/laboratoryGroups/{grpid}', data, config),
    updateLaboratoryGroup: async (data, config) => await client.patch('/api/settings/laboratoryGroups/{grpid}', data, config),
  },

  laboratoryGroupFilters: {
    getLaboratoryGroupFilters: async (data, config) => await client.get('/api/settings/laboratoryGroupFilters', data, config),
    setLaboratoryGroupFilters: async (data, config) => await client.put('/api/settings/laboratoryGroupFilters', data, config),
  },

  doctorGroups: {
    listDoctorGroups: async (data, config) => await client.get('/api/settings/doctorGroups', data, config),
    createDoctorGroup: async (data, config) => await client.post('/api/settings/doctorGroups', data, config),
    readDoctorGroup: async (data, config) => await client.get('/api/settings/doctorGroups/{gid}', data, config),
    deleteDoctorGroup: async (data, config) => await client.delete('/api/settings/doctorGroups/{gid}', data, config),
    updateDoctorGroup: async (data, config) => await client.patch('/api/settings/doctorGroups/{gid}', data, config),
  },

  doctorGroupOffice: {
    readDoctorGroupOfficeAssignments: async (data, config) => await client.get('/api/settings/doctorGroupOffice/assignments', data, config),
    listDoctorsPerGroupOffices: async (data, config) => await client.get('/api/settings/doctorGroupOffice/doctors', data, config),
  },

  jobs: {
    importElasticsearchRequirements: async (data, config) => await client.post('/api/settings/jobs/elastic/requirements', data, config),
    importElasticsearchPatients: async (data, config) => await client.post('/api/settings/jobs/elastic/patients', data, config),
    importElasticsearchDoctors: async (data, config) => await client.post('/api/settings/jobs/elastic/doctors', data, config),
    runDoctorTopRequirementsJob: async (data, config) => await client.post('/api/settings/jobs/elastic/doctorTopRequirements', data, config),
  },

  system: {
    clearCache: async (data, config) => await client.post('/api/settings/system/clearCache', data, config),
    readSystemConfig: async (data, config) => await client.get('/api/settings/system', data, config),
    updateSystemConfig: async (data, config) => await client.put('/api/settings/system', data, config),
  },

  mails: {
    getMailSmtpSettings: async (data, config) => await client.get('/api/settings/mails/smtp', data, config),
    setMailSmtpSettings: async (data, config) => await client.put('/api/settings/mails/smtp', data, config),
    getMailTemplateGroups: async (data, config) => await client.get('/api/settings/mails/groups', data, config),
    setMailTemplateGroups: async (data, config) => await client.put('/api/settings/mails/groups', data, config),
    setMailTemplateGroupLogo: async (data, config) => await client.patch('/api/settings/mails/groups/{mtgid}/logo', client.createFormData(data), config),
    getMailTemplate: async (data, config) => await client.get('/api/settings/mails/templates/{locale}/{type}', data, config),
    setMailTemplate: async (data, config) => await client.put('/api/settings/mails/templates/{locale}/{type}', data, config),
    sendMailTemplate: async (data, config) => await client.post('/api/settings/mails/templates/send/{locale}/{type}', data, config),
    previewMailTemplate: async (data, config) => await client.get('/api/settings/mails/templates/preview/{locale}/{type}', data, config),
  },

  lanr: {
    getLanrSettings: async (data, config) => await client.get('/api/settings/lanr/{aid}', data, config),
    updateLanrSettings: async (data, config) => await client.put('/api/settings/lanr/{aid}', data, config),
  },

  smsSettings: {
    readSmsConfig: async (data, config) => await client.get('/api/settings/sms', data, config),
    updateSmsConfig: async (data, config) => await client.put('/api/settings/sms', data, config),
  },

  preferenceSettings: {
    readAllPreferences: async (data, config) => await client.get('/api/settings/preferences', data, config),
    resetPreferenceByKey: async (data, config) => await client.delete('/api/settings/preferences/{key}', data, config),
  },

  lexicaSettings: {
    readArticle: async (data, config) => await client.get('/api/lexica/settings/articles/{id}', data, config),
    updateArticle: async (data, config) => await client.patch('/api/lexica/settings/articles/{id}', data, config),
    deleteArticle: async (data, config) => await client.delete('/api/lexica/settings/articles/{id}', data, config),
    listArticles: async (data, config) => await client.get('/api/lexica/settings/articles', data, config),
    createArticle: async (data, config) => await client.post('/api/lexica/settings/articles', data, config),
    createArticleAnalysis: async (data, config) => await client.post('/api/lexica/settings/articleAnalyses', data, config),
    deleteArticleAnalysis: async (data, config) => await client.delete('/api/lexica/settings/articleAnalyses/{id}', data, config),
    listRequirementHints: async (data, config) => await client.get('/api/lexica/settings/hints', data, config),
    createRequirementHint: async (data, config) => await client.post('/api/lexica/settings/hints', data, config),
    readRequirementHint: async (data, config) => await client.get('/api/lexica/settings/hints/{id}', data, config),
    updateRequirementHint: async (data, config) => await client.patch('/api/lexica/settings/hints/{id}', data, config),
    deleteRequirementHint: async (data, config) => await client.delete('/api/lexica/settings/hints/{id}', data, config),
    createHintRequirement: async (data, config) => await client.post('/api/lexica/settings/hintRequirements', data, config),
    deleteHintRequirement: async (data, config) => await client.delete('/api/lexica/settings/hintRequirements/{id}', data, config),
  },

  lexica: {
    listArticles: async (data, config) => await client.get('/api/lexica/articles', data, config),
    readArticle: async (data, config) => await client.get('/api/lexica/articles/{id}', data, config),
    listRequirementHints: async (data, config) => await client.get('/api/lexica/hints', data, config),
    readRequirementHint: async (data, config) => await client.get('/api/lexica/hints/{id}', data, config),
  },

  webshopProduct: {
    createWebshopProduct: async (data, config) => await client.post('/api/webshop/product', data, config),
    listWebshopProductLimitForToday: async (data, config) => await client.get('/api/webshop/product/{productId}/availabilityToday', data, config),
  },

  webshopVoucher: {
    createWebshopVoucher: async (data, config) => await client.post('/api/webshop/voucher', data, config),
    createMvzMartinsriedWebshopVoucher: async (data, config) => await client.post('/api/webshop/voucher/mvz', data, config),
  },

  webshop: {
    preparePaymentForWebshopOrder: async (data, config) => await client.post('/api/webshop/preparePayment', data, config),
    insertWebshopOrder: async (data, config) => await client.post('/api/webshop', data, config),
    listOrdersForDeviceId: async (data, config) => await client.get('/api/webshop/{deviceId}', data, config),
    completeWebshopOrder: async (data, config) => await client.post('/api/webshop/{webshopOrderId}', data, config),
  },

  cases: {
    getCasesConfig: async (data, config) => await client.get('/api/cases/config', data, config),
    listCases: async (data, config) => await client.get('/api/cases', data, config),
    readCase: async (data, config) => await client.get('/api/cases/{id}', data, config),
  },

  casesSettings: {
    readGeneralCasesConfig: async (data, config) => await client.get('/api/cases/settings/general', data, config),
    updateGeneralCasesConfig: async (data, config) => await client.put('/api/cases/settings/general', data, config),
    readUserCasesConfig: async (data, config) => await client.get('/api/cases/settings/user/{entityId}/{type}', data, config),
    updateUserCasesConfig: async (data, config) => await client.put('/api/cases/settings/user/{entityId}/{type}', data, config),
  },

  workstations: {
    getWorkstationByAlias: async (data, config) => await client.get('/api/connect/workstations/alias/{alias}', data, config),
    listWorkstations: async (data, config) => await client.get('/api/connect/workstations/{aid}', data, config),
  },

  exchangeStorage: {
    reExportFiles: async (data, config) => await client.post('/api/exchange/storage/reexport', data, config),
    getLTD3EmbeddedFile: async (data, config) => await client.get('/api/exchange/storage/embedded/{id}', data, config),
    downloadFile: async (data, config) => await client.get('/api/exchange/storage/{id}', data, config),
    deleteFiles: async (data, config) => await client.delete('/api/exchange/storage', data, config),
    listFiles: async (data, config) => await client.get('/api/exchange/storage', data, config),
    uploadFiles: async (data, config) => await client.post('/api/exchange/storage', client.createFormData(data), config),
  },

  exchangeSettings: {
    updateImportSettings: async (data, config) => await client.patch('/api/exchange/settings/import', data, config),
    getImportSettings: async (data, config) => await client.get('/api/exchange/settings/import', data, config),
    updateExportSettings: async (data, config) => await client.patch('/api/exchange/settings/export/{aid}', data, config),
    getExportSettings: async (data, config) => await client.get('/api/exchange/settings/export/{aid}', data, config),
  },

  storeCategories: {
    getStoreCategoryTreeForDoctor: async (data, config) => await client.get('/api/store/categories/doctor/{aid}', data, config),
    listAllStoreCategories: async (data, config) => await client.get('/api/store/categories/all', data, config),
    deleteStoreCategory: async (data, config) => await client.delete('/api/store/categories/{id}', data, config),
    updateStoreCategory: async (data, config) => await client.patch('/api/store/categories/{id}', data, config),
    readStoreCategory: async (data, config) => await client.get('/api/store/categories/{id}', data, config),
    listStoreCategories: async (data, config) => await client.get('/api/store/categories', data, config),
    createStoreCategory: async (data, config) => await client.post('/api/store/categories', data, config),
  },

  storeManufacturers: {
    deleteStoreManufacturer: async (data, config) => await client.delete('/api/store/manufacturers/{id}', data, config),
    updateStoreManufacturer: async (data, config) => await client.patch('/api/store/manufacturers/{id}', data, config),
    readStoreManufacturer: async (data, config) => await client.get('/api/store/manufacturers/{id}', data, config),
    listStoreManufacturers: async (data, config) => await client.get('/api/store/manufacturers', data, config),
    createStoreManufacturer: async (data, config) => await client.post('/api/store/manufacturers', data, config),
  },

  storeProducts: {
    getStoreProductThumbnail: async (data, config) => await client.get('/api/store/products/thumbnail/{id}', data, config),
    getStoreProductImage: async (data, config) => await client.get('/api/store/products/image/{id}', data, config),
    uploadStoreProductImage: async (data, config) => await client.post('/api/store/products/{id}/image', client.createFormData(data), config),
    deleteStoreProduct: async (data, config) => await client.delete('/api/store/products/{id}', data, config),
    updateStoreProduct: async (data, config) => await client.patch('/api/store/products/{id}', data, config),
    readStoreProduct: async (data, config) => await client.get('/api/store/products/{id}', data, config),
    listStoreProducts: async (data, config) => await client.get('/api/store/products', data, config),
    createStoreProduct: async (data, config) => await client.post('/api/store/products', data, config),
  },

  storeOrders: {
    sendCurrentUserOrder: async (data, config) => await client.post('/api/store/orders/current', data, config),
    setCurrentUserOrder: async (data, config) => await client.put('/api/store/orders/current', data, config),
    getCurrentUserOrder: async (data, config) => await client.get('/api/store/orders/current', data, config),
    completeStoreOrder: async (data, config) => await client.post('/api/store/orders/complete/{id}', data, config),
    commissionStoreOrder: async (data, config) => await client.post('/api/store/orders/commission/{id}', data, config),
    cancelStoreOrder: async (data, config) => await client.post('/api/store/orders/cancel/{id}', data, config),
    deleteStoreOrder: async (data, config) => await client.delete('/api/store/orders/{id}', data, config),
    updateStoreOrder: async (data, config) => await client.patch('/api/store/orders/{id}', data, config),
    readStoreOrder: async (data, config) => await client.get('/api/store/orders/{id}', data, config),
    listStoreOrders: async (data, config) => await client.get('/api/store/orders', data, config),
    createStoreOrder: async (data, config) => await client.post('/api/store/orders', data, config),
  },

  storeSettings: {
    updateUserStoreConfig: async (data, config) => await client.patch('/api/store/config/user', data, config),
    readUserStoreConfig: async (data, config) => await client.get('/api/store/config/user', data, config),
    updateGeneralStoreConfig: async (data, config) => await client.patch('/api/store/config/general', data, config),
    readGeneralStoreConfig: async (data, config) => await client.get('/api/store/config/general', data, config),
    getStoreConfig: async (data, config) => await client.get('/api/store/config', data, config),
  },

  cache: {
    getSystemCache: async (data, config) => await client.get('/api/system/cache', data, config),
  },

  logs: {
    readLogEntry: async (data, config) => await client.get('/api/system/logs/{id}', data, config),
    listLogEntries: async (data, config) => await client.get('/api/system/logs', data, config),
  },

  metrics: {
    getSystemMetrics: async (data, config) => await client.get('/api/system/metrics/{interval}', data, config),
    resetSystemMetrics: async (data, config) => await client.delete('/api/system/metrics', data, config),
  },

  notificationSettings: {
    resetUserTypeNotificationsPreferences: async (data, config) => await client.delete('/api/notifications/settings/user/{type}', data, config),
    resetUserNotificationsPreferences: async (data, config) => await client.delete('/api/notifications/settings/user/{entityId}/{type}', data, config),
    updateUserNotificationsConfig: async (data, config) => await client.put('/api/notifications/settings/user/{entityId}/{type}', data, config),
    readUserNotificationsConfig: async (data, config) => await client.get('/api/notifications/settings/user/{entityId}/{type}', data, config),
    updateGeneralNotificationsConfig: async (data, config) => await client.put('/api/notifications/settings/general', data, config),
    readGeneralNotificationsConfig: async (data, config) => await client.get('/api/notifications/settings/general', data, config),
  },

  notifications: {
    readAllNotifications: async (data, config) => await client.post('/api/notifications/readAll', data, config),
    messenteSMSDeliveryReport: async (data, config) => await client.get('/api/notifications/messente/{id}', data, config),
    getNotificationLog: async (data, config) => await client.get('/api/notifications/logs/{id}', data, config),
    listNotificationLogs: async (data, config) => await client.get('/api/notifications/logs', data, config),
    countNotifications: async (data, config) => await client.get('/api/notifications/count', data, config),
    clearQueue: async (data, config) => await client.get('/api/notifications/clearQueue', data, config),
    dismissNotification: async (data, config) => await client.delete('/api/notifications/{id}', data, config),
    readNotification: async (data, config) => await client.get('/api/notifications/{id}', data, config),
    dismissAllNotifications: async (data, config) => await client.delete('/api/notifications', data, config),
    listNotifications: async (data, config) => await client.get('/api/notifications', data, config),
  },

});

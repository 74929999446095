import React, { useRef } from 'react';
import { useLabstoreOrderSelectors, useLabstoreSelectors } from 'modules/store/store/useLabstoreStore';
import { Control, Modal } from 'components';
import { StoreOrderStatus, UserType } from 'interfaces/api';
import messages from 'messages';
import { StoreOrderStatusColor } from 'modules/store/constants';
import { StoreBasket } from 'modules/store/components/StoreModal/StoreBasket';
import { StoreProductsListLayout } from 'modules/store/containers/StoreProductsListLayout';
import './store.less';
import { StoreProductsListLayoutBindingsContext } from 'modules/store/containers/StoreProductsListLayout/useListLayoutBindingsContext';
import { createListLayoutBindingsStore, Guard } from 'containers';
import { ProductDetailModal } from 'modules/store/components/StoreModal/ProductDetailModal';
import { faPencil } from '@fortawesome/pro-light-svg-icons';
import cx from 'classnames';

export const StoreModal = () => {

  const opened = useLabstoreSelectors.opened();
  const close = useLabstoreSelectors.close();
  const aid = useLabstoreOrderSelectors.aid();
  const doctor = useLabstoreOrderSelectors.doctor();
  const setSelectDoctor = useLabstoreSelectors.setShowSelectDoctor();

  const listRefStore = useRef(createListLayoutBindingsStore()).current;

  return (
    <Modal
      open={opened && !!aid}
      fullScreen
      title={messages.store.create}
      footer={null}
      keyboard={false}
      destroyOnClose
      onCancel={close}
      children={(
        <StoreProductsListLayoutBindingsContext.Provider value={listRefStore}>
          <StoreProductsListLayout/>
          <ProductDetailModal/>
        </StoreProductsListLayoutBindingsContext.Provider>
      )}
      controls={[(
        <Guard forbidden={UserType.ARZ}>
          <Control
            icon={faPencil}
            label={doctor?.displayName}
            className={cx('wizard-header-input-control')}
            onClick={() => {
              setSelectDoctor(true);
            }}
          />
        </Guard>
      )]}
      withSideBar={{
        header: {
          title: messages.orders.wizard.basket.label,
          flag: StoreOrderStatusColor[StoreOrderStatus.Draft],
          controls: [],
        },
        body: (<StoreBasket/>),
      }}
    />
  );

};

import { ComposedOrderProperties, GdtPatientGetPatientReportsResponse, OrderFrontendLogEntry, Report, ReportHistoryValue, WriteableOrderProperties } from 'interfaces/api';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { createSelectors } from 'utils/helpers';

export const globalSettingKeys = [
  'scheduled_at',
  'apid',
  'clientname',
  'printerAliasName',
  'workstationChecked',
  'sampleDate',
  'sampleTime',
] as const;

export type GlobalOrderSettingsKey = (typeof globalSettingKeys)[number];

export type GlobalOrderSettings = Pick<WriteableOrderProperties, GlobalOrderSettingsKey>;

export interface OrderStorePropsState {
  wizardVisible: boolean;
  showPatientSelectModal: boolean;
  orders: WriteableOrderProperties[];
  globalSettings: GlobalOrderSettings;
  logs: OrderFrontendLogEntry[];
  isUntouched: boolean;
  immutableRequirementIds: string[];
  initialProfileName: string;
  initialInvoiceTo: string;
  selectedOrderIndex: number;
  latestSelectedFormId: number;
  showCostUnitSelect: boolean;
  samePatientOrders: ComposedOrderProperties[];
  showUrgentNotificationModal: boolean;
  reports: Report[];
  history: ReportHistoryValue[];
  showAccidentAdditionalFieldsModal: boolean;
  showProfessionalAssociationAdditionalFieldsModal: boolean;
}

export interface OrdersStoreSetState {
  setWizardVisible: (wizardVisible: boolean) => void;
  toggleWizardVisible: () => void;
  setShowPatientSelectModal: (showPatientSelectModal: boolean) => void;
  setOrders: (orders: WriteableOrderProperties[]) => void;
  removeOrderAtIndex: (idx: number) => void;
  setGlobalSettings: (globalSettings: GlobalOrderSettings) => void;
  setLogs: (logs: OrderFrontendLogEntry[]) => void;
  setIsUntouched: (isUntouched: boolean) => void;
  setImmutableRequirementIds: (immutableRequirementIds: string[]) => void;
  setInitialProfileName: (initialProfileName: string) => void;
  setInitialInvoiceTo: (initialInvoiceTo: string) => void;
  selectOrderAtIndex: (selectedOrderIndex: number) => void;
  setLatestSelectedFormId: (latestSelectedFormId: number) => void;
  setShowCostUnitSelect: (showCostUnitSelect: boolean) => void;
  setSamePatientOrders: (samePatientOrders: ComposedOrderProperties[]) => void;
  setShowUrgentNotificationModal: (showUrgentNotificationModal: boolean) => void;
  resetReports: () => void;
  setPatientReports: (data: GdtPatientGetPatientReportsResponse) => void;
  setShowAccidentAdditionalFieldsModal: (showAccidentAdditionalFieldsModal: boolean) => void;
  setShowProfessionalAssociationAdditionalFieldsModal: (showAccidentAdditionalFieldsModal: boolean) => void;
}

export const initialOrder: WriteableOrderProperties = {
  patient: {},
  switzerland: {},
  requirements: [],
  selectedDiagnoses: {},
};

export const initialOrdersState: OrderStorePropsState = {
  wizardVisible: false,
  showPatientSelectModal: false,
  orders: [initialOrder],
  globalSettings: {},
  logs: [],
  isUntouched: true,
  immutableRequirementIds: [],
  initialProfileName: null,
  initialInvoiceTo: null,
  selectedOrderIndex: undefined,
  latestSelectedFormId: null,
  showCostUnitSelect: false,
  samePatientOrders: [],
  showUrgentNotificationModal: false,
  reports: [],
  history: [],
  showAccidentAdditionalFieldsModal: false,
  showProfessionalAssociationAdditionalFieldsModal: false,
};

export type OrderStore = OrderStorePropsState & OrdersStoreSetState;

export const useOrdersStore = create<OrderStore>()(devtools((set, get) => ({
  ...initialOrdersState,
  setWizardVisible: wizardVisible => set({ wizardVisible }),
  toggleWizardVisible: () => set(({ wizardVisible }) => ({ wizardVisible: !wizardVisible })),
  setShowPatientSelectModal: showPatientSelectModal => set({ showPatientSelectModal }),
  setOrders: orders => set({ orders }),
  removeOrderAtIndex: index => set(({ orders }) => ({ orders: orders.filter((o, idx) => idx !== index) })),
  setGlobalSettings: globalSettings => set({ globalSettings }),
  setLogs: logs => set({ logs }),
  setIsUntouched: isUntouched => set({ isUntouched }),
  setImmutableRequirementIds: immutableRequirementIds => set({ immutableRequirementIds }),
  setInitialProfileName: initialProfileName => set({ initialProfileName }),
  setInitialInvoiceTo: initialInvoiceTo => set({ initialInvoiceTo }),
  selectOrderAtIndex: selectedOrderIndex => set({ selectedOrderIndex }),
  setLatestSelectedFormId: latestSelectedFormId => set({ latestSelectedFormId }),
  setShowCostUnitSelect: showCostUnitSelect => set({ showCostUnitSelect }),
  setSamePatientOrders: samePatientOrders => set({ samePatientOrders }),
  setShowUrgentNotificationModal: showUrgentNotificationModal => set({ showUrgentNotificationModal }),
  resetReports: () => set({ reports: [], history: [] }),
  setPatientReports: ({ reports, history }) => set({ reports, history }),
  setShowAccidentAdditionalFieldsModal: showAccidentAdditionalFieldsModal => set({ showAccidentAdditionalFieldsModal }),
  setShowProfessionalAssociationAdditionalFieldsModal: showProfessionalAssociationAdditionalFieldsModal => set({ showProfessionalAssociationAdditionalFieldsModal }),
})));

export const useOrdersSelectors = createSelectors(useOrdersStore).use;

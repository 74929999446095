import { create } from 'zustand';
import { Doctor, StoreCategory, StoreManufacturer, StoreOrder, StoreProduct, UserType } from 'interfaces/api';
import { useCallback, useMemo } from 'react';
import { createSelectors } from 'utils/helpers';
import { useAuthUser } from 'modules/auth/providers';
import { devtools } from 'zustand/middleware';
import { useApi } from 'providers';
import { find, findIndex, omit } from 'lodash';

/**
 * Represents the state properties for the Labstore Store.
 */
type LabstoreOrderPropsState = Partial<StoreOrder> & {};

/**
 * Interface representing the state management actions for the Labstore store.
 */
interface LabstoreOrderSetState {
  reset: () => void;
  setAid: (aid: number) => void;
  setDoctor: (doctor: Doctor) => void;
}

/**
 * Represents the initial state of the Labstore Store.
 */
const initialOrderState: LabstoreOrderPropsState = {
  id: undefined,
  aid: undefined,
  doctor: undefined,
  items: [],
};

/**
 * useLabstoreStore is a state management hook created using the Zustand library combined with Redux DevTools
 * for state debugging and persistence. It manages the state for lab store application components and settings.
 */
export const useLabstoreOrderStore = create<LabstoreOrderPropsState & LabstoreOrderSetState>()(devtools((set, get) => ({
  ...initialOrderState,
  reset: () => set(initialOrderState),
  setAid: aid => set({ aid }),
  setDoctor: doctor => set({ doctor }),
})));

/**
 * A variable that holds the selectors returned from the `useLabstoreStore`.
 */
export const useLabstoreOrderSelectors = createSelectors(useLabstoreOrderStore).use;

/**
 * Represents the state properties for the Labstore Store.
 */
interface LabstorePropsState {
  loading: boolean;
  opened: boolean;
  showSelectDoctor: boolean;
  categories: StoreCategory[];
  manufacturers: StoreManufacturer[];
  selectedProduct: StoreProduct;
  selectedCategoryId: number;
  selectedManufacturerId: number;
}

/**
 * Interface representing the state management actions for the Labstore store.
 */
interface LabstoreSetState {
  open: () => void;
  close: () => void;
  reset: () => void;
  setLoading: (loading: boolean) => void;
  setShowSelectDoctor: (showSelectDoctor: boolean) => void;
  setSelectedCategoryId: (id: number) => void;
  getSelectedCategory: () => StoreCategory;
  setSelectedManufacturerId: (id: number) => void;
}

/**
 * Represents the initial state of the Labstore Store.
 */
const initialState: LabstorePropsState = {
  loading: false,
  opened: false,
  showSelectDoctor: false,
  categories: [],
  manufacturers: [],
  selectedCategoryId: null,
  selectedManufacturerId: undefined,
  selectedProduct: undefined,
};

/**
 * useLabstoreStore is a state management hook created using the Zustand library combined with Redux DevTools
 * for state debugging and persistence. It manages the state for lab store application components and settings.
 */
export const useLabstoreStore = create<LabstorePropsState & LabstoreSetState>()(devtools((set, get) => ({
  ...initialState,
  setLoading: loading => set({ loading }),
  open: () => set({ opened: true }),
  close: () => set({ opened: false }),
  setShowSelectDoctor: showSelectDoctor => set({ showSelectDoctor }),
  reset: () => set(initialState),
  setSelectedCategoryId: selectedCategoryId => set({ selectedCategoryId }),
  getSelectedCategory: () => find(get().categories, { id: get().selectedCategoryId }),
  setSelectedManufacturerId: selectedManufacturerId => set({ selectedManufacturerId }),
})));

/**
 * A variable that holds the selectors returned from the `useLabstoreStore`.
 */
export const useLabstoreSelectors = createSelectors(useLabstoreStore).use;

/**
 * Custom hook that loads store configuration including categories and manufacturers.
 */
export const useLoadStoreConfig = () => {

  const {
    storeCategories: { getStoreCategoryTreeForDoctor },
    storeManufacturers: { listStoreManufacturers },
  } = useApi();

  const aid = useLabstoreOrderSelectors.aid();

  return useCallback(async (aid: number) => {

    if (aid !== undefined) {
      useLabstoreStore.setState({ loading: true });
      const categories = await getStoreCategoryTreeForDoctor({ aid });
      const manufacturers = await listStoreManufacturers();
      useLabstoreStore.setState({ categories, manufacturers, loading: false });
    }

  }, [aid, getStoreCategoryTreeForDoctor, listStoreManufacturers]);

};

/**
 * Custom hook that returns a callback to set a doctor in the labstore
 * and loads associated store configuration.
 */
export const useSetLabstoreDoctor = () => {

  const loadConfig = useLoadStoreConfig();
  const { storeOrders: { setCurrentUserOrder } } = useApi();

  return useCallback(async (doctor: Doctor) => {
    useLabstoreOrderStore.setState({ aid: doctor.aid, doctor });
    await loadConfig(doctor.aid);
  }, [setCurrentUserOrder, loadConfig]);

};

/**
 * Custom hook that handles the opening of the lab store for authenticated users.
 */
export const useOpenLabstore = () => {

  const user = useAuthUser();

  const setDoctor = useSetLabstoreDoctor();
  const aid = useLabstoreOrderSelectors.aid();
  const open = useLabstoreSelectors.open();

  const resetStore = useLabstoreSelectors.reset();
  const resetOrderStore = useLabstoreOrderSelectors.reset();

  return useCallback(async (order?: StoreOrder) => {

    if (user?.type === UserType.ARZ && !aid) {
      await setDoctor({ aid: user.entityId });
    }

    if (user?.type !== UserType.ARZ) {
      resetStore();
      resetOrderStore();
    }

    if (order) {
      useLabstoreOrderStore.setState(order);
    }

    open();
  }, [user, aid, setDoctor]);

};

/**
 * Checks if a given product is present in the user's items.
 */
export const productInBasket = (product: StoreProduct) => {
  const items = useLabstoreOrderStore.getState().items;
  return find(items, { productId: product.id });
};

/**
 * A custom hook that provides a function to add or update a product in the user's store items.
 */
export const useUpsertStoreProduct = () => {

  const user = useAuthUser();

  const { storeOrders: { setCurrentUserOrder } } = useApi();

  return useCallback(async (product: StoreProduct, amount: number) => {

    const inBasket = productInBasket(product);
    const updatedProduct = { product, amount, productId: product.id };

    const currentBasket = [...useLabstoreOrderStore.getState().items];

    inBasket ? currentBasket.splice(findIndex(currentBasket, { productId: product.id }), 1, updatedProduct) : currentBasket.push(updatedProduct);

    useLabstoreOrderStore.setState({ items: currentBasket });

    if (user?.type === UserType.ARZ) {
      const items = currentBasket.map(b => omit(b, 'product'));
      await setCurrentUserOrder({ items });
    }

  }, [setCurrentUserOrder]);
};

/**
 * Custom hook to remove a product from the store items.
 */
export const useRemoveStoreProduct = () => {

  const user = useAuthUser();

  const { storeOrders: { setCurrentUserOrder } } = useApi();

  return useCallback(async (product: StoreProduct) => {

    const items = useLabstoreOrderStore.getState().items.filter(b => b.productId !== product.id);
    useLabstoreOrderStore.setState({ items });

    if (user?.type === UserType.ARZ) {
      await setCurrentUserOrder({ items: items.map(b => omit(b, 'product')) });
    }

  }, [setCurrentUserOrder]);
};

/**
 * A custom hook that calculates the total count of items in the store items.
 */
export const useStoreBasketCount = () => {
  const items = useLabstoreOrderSelectors.items();
  return useMemo(() => items?.length || 0, [items]);
};

/**
 * Custom hook for setting the current order in the store.
 */
export const useSetCurrentOrder = () => {

  const aid = useLabstoreOrderSelectors.aid();

  const loadConfig = useLoadStoreConfig();

  const resetStore = useLabstoreSelectors.reset();
  const resetOrderStore = useLabstoreOrderSelectors.reset();

  return useCallback(async (currentOrder: StoreOrder) => {
    if (currentOrder) {
      useLabstoreOrderStore.setState({ ...currentOrder, items: currentOrder.items || [] });
      if (aid !== currentOrder.aid) {
        await loadConfig(currentOrder.aid);
      }
    } else {
      resetStore();
      resetOrderStore();
    }
  }, [loadConfig, aid]);

};

import React from 'react';
import { Container, Control, List } from 'components';
import { EmptyBasket } from 'modules/orders/containers/OrderWizard/components';
import { useLabstoreOrderSelectors, useLabstoreOrderStore, useLabstoreSelectors, useLabstoreStore } from 'modules/store/store/useLabstoreStore';
import { filter } from 'lodash';
import { useProductImageSrc } from 'modules/store/utils';
import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';
import { useApi } from 'providers';
import { useStoreOrdersListLayoutBindingsContext } from 'modules/store/containers/StoreOrdersListLayout/useListLayoutBindingsContext';
import { useAuthUser } from 'modules/auth/providers';
import { UserType } from 'interfaces/api';

export const StoreBasket = () => {

  const user = useAuthUser();

  const orderId = useLabstoreOrderSelectors.id();
  const items = useLabstoreOrderSelectors.items();
  const getProductImage = useProductImageSrc();

  const { storeOrders: { sendCurrentUserOrder, createStoreOrder, updateStoreOrder } } = useApi();

  const close = useLabstoreSelectors.close();

  const reloadList = useStoreOrdersListLayoutBindingsContext?.(s => s.reloadList);
  const reloadDetail = useStoreOrdersListLayoutBindingsContext?.(s => s.reloadDetail);

  return (
    <Container grow shrink className={'store-basket-container'}>
      {items.length === 0 && <EmptyBasket/>}
      {items.length > 0 && (
        <Container grow shrink>
          <Container grow shrink>
            <Container shrink scrollY style={{ height: 'auto' }}>

              <List
                items={items.map(item => ({
                  id: item.productId,
                  title: item.product.name,
                  badge: item.amount,
                  images: filter([getProductImage(item.product, true)]),
                  subtitle: item.product.manufacturer?.name,
                  meta: item,
                }))}
                onItemClick={listItem => useLabstoreStore.setState({ selectedProduct: listItem.meta.product })}
              />
            </Container>

          </Container>

          <ul className={'bottom-buttons'}>
            <li>
              <Control
                icon={faPaperPlane}
                label={messages.store.submitOrder}
                button={{
                  block: true,
                  type: 'primary',
                }}
                notifications={{
                  success: messages.store.submitteOrderSuccess,
                  error: false,
                }}
                confirm={{
                  title: messages.store.submitConfirm.title,
                  content: messages.store.submitConfirm.content,
                }}
                onClick={async () => {
                  if (user.type === UserType.ARZ) {
                    await sendCurrentUserOrder();
                  } else if (orderId > 0) {
                    await updateStoreOrder({ ...useLabstoreOrderStore.getState(), id: orderId });
                    reloadDetail();
                  } else {
                    await createStoreOrder({ ...useLabstoreOrderStore.getState() });
                  }
                  close();
                  reloadList?.();
                }}
              />
            </li>
          </ul>

        </Container>
      )}
    </Container>
  );
};
